<template>
  <div class="d-flex flex-column" style="min-height: 2000px;">
    
    <div v-if="order && order.status === 'WAIT_PAYMENT' && order.cancel_in_seconds !== null && cancel_in_seconds > 0">
      <p style="text-align: center; margin-top: 12px;">
        {{$t('SHOP_ORDER.CANCEL_IN_SECONDS', { seconds: cancel_in_seconds })}}
      </p>
    </div>

    <div v-if="order && (order.status === 'CANCELLED' || ( order.status === 'WAIT_PAYMENT' && cancel_in_seconds < 0))">
      <b-card style="min-width: 400px;" class="mt-4 mb-16">
        <div class="col-12 text-center">
          <i class="fa fa-minus-circle" style="font-size: 42px; color: #fe0000;"></i>
        </div>

        <div class="d-flex justify-content-center mb-8 mt-8">
          <span class="text-center" style="font-weight: bold;">{{ $t('PUBLIC.PAYMENT.PAYMENT_TIMEOUT') }}</span>
        </div>

        <div class="d-flex justify-content-center mb-8 mt-8">
          <span class="text-center">{{ $t('PUBLIC.PAYMENT.START_OVER') }}</span>
        </div>
    
      </b-card>
    </div>

    <div v-else>

      <b-card class="mt-4 mb-4" style="min-width: 400px;">

        <OrderSummaryComponent
          v-if="order"
          :order="order"
        />

      </b-card>


      <div v-if="error">
        <div v-if="error === 'invalid_shop_order_id'">
          {{ $t('PUBLIC.PAYMENT.INVALID_SHOP_ORDER_ID') }}
        </div>
        <div v-if="error === 'invalid_token'">
          {{ $t('PUBLIC.PAYMENT.INVALID_TOKEN') }}
        </div>
      </div>

      <SelectPaymentComponent 
        v-if="!payment_complete && order"
        @selected="payment_method_selected"
        :order="order"
      />

      <SwishPaymentComponent
        id="scroll-to-element" 
        v-if="selected_payment_method === 'SWISH' && !payment_complete"
        :order="order"
        @updated="order_updated"
        @start_polling="start_polling"
      />

      <StripePaymentComponent 
        id="scroll-to-element" 
        v-if="selected_payment_method === 'STRIPE' && !payment_complete"
        :order="order"
        @updated="order_updated"
        @start_polling="start_polling"
      />
      
      <p style="margin-top: 128px;"></p>

    </div>

  </div>
</template>

<script>
import axios from 'axios';
import PaymentComplete from '@/view/components/PaymentComplete.vue';
import PaymentOrderDetails from '@/view/components/PaymentOrderDetails.vue';
import PendingPaymentInfo from '@/view/components/PendingPaymentInfo.vue';
import SelectPaymentComponent from '@/view/pages/members/payment/SelectPaymentComponent.vue';
import SwishPaymentComponent from '@/view/pages/members/payment/SwishPaymentComponent.vue';
import StripePaymentComponent from '@/view/pages/members/payment/StripePaymentComponent.vue';
import OrderSummaryComponent from '@/view/pages/members/payment/OrderSummaryComponent.vue';

import { setKeyValue, getKeyValue } from '@/core/services/simpleStore';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';
import { is_mobile } from '@/core/services/utils';


export default {
  name: 'SelectPaymentPage',
  props: [],
  mixins: [ toasts ],
  components: {
    SwishPaymentComponent,
    SelectPaymentComponent,
    PaymentComplete,
    PaymentOrderDetails,
    PendingPaymentInfo,
    OrderSummaryComponent,
    StripePaymentComponent
  },
  computed: {
    payment_complete() {
      return this.order && this.order.status === 'PAID_IN_FULL';
    },

    min_width() {
      return {
        'min-width': is_mobile()  ? '460px' : '640px'
      };
    },
    
  },
  data() {
    return {
      cancel_in_seconds: 0,
      selected_payment_method: null,
      shop_order_id: null,
      token: null,
      error: null,
      order: null,
      email: null
    };
  },
  async mounted() {
    this.shop_order_id = this.$route.params.shop_order_id;
    this.token = this.$route.params.token;

    if (!this.shop_order_id) {
      this.error = 'invalid_shop_order_id';
      return;
    }

    if (!this.token) {
      this.error = 'invalid_token';
      return;
    }

    this.order = await this.get_order(this.token, this.shop_order_id);

    this.cancel_in_seconds = this.order.cancel_in_seconds;

    setInterval(()=>{
      
      this.cancel_in_seconds = this.cancel_in_seconds - 1;
    }, 1000);


    this.start_polling();

  },
  watch: {
    
  },
  beforeDestroy() {
    this.stop_poll_shop_order();
  },
  methods: {

    order_updated(order) {
      this.order = { ...order };
    },

    async get_order(token, shop_order_id) {
      try {
        const res = await axios.get(`/shop_order/token/${token}/${shop_order_id}`);

        if (res.status === 200) {
          return res.data;
        }
      }
      catch (err) {
        console.error('get_order error', err);
      }

      throw `unable to get order`;
    },

    scroll_to_smooth_classname(classname) {

      this.$nextTick(()=>{
        
        document.getElementById('scroll-to-element').scrollIntoView({
          block: 'start',
          behavior: "smooth", 
        });
      });
    },

    payment_method_selected(method) {
      switch (method) {
        case 'SWISH': {
          this.selected_payment_method = 'SWISH';
          break;
        }
        case 'STRIPE': {
          this.selected_payment_method = 'STRIPE';
          break;
        }
      }

      this.scroll_to_smooth_classname('payment-component-section');
    },

    start_polling() {
      if (this.interval_id) {
        clearInterval(this.interval_id);
      }

      this.interval_id = setInterval(()=>{
        this.poll_shop_order();
      }, 5000);
    },

    async poll_shop_order() {
      try {
        const item = await this.get_order(this.order.token, this.order.shop_order_id);

        if (item) {
          this.order = { ...item };

          switch (this.order.status) {
            case 'PAID_IN_FULL':
            case 'DELETED':
            case 'FAILED': 
            case 'DONE':
            case 'CANCELLED': {
              this.stop_poll_shop_order();
              window.scrollTo(0, 0);              
            }
          }
        }
      }
      catch (err) {
        console.error('poll_shop_order error', err);
      }
    },

    stop_poll_shop_order() {
      if (this.interval_id) {
        clearInterval(this.interval_id);
      }
    },

  }
};
</script>


<style lang="css" scoped>
.amount {
  font-size: 30px;
  font-weight: 600;
  line-height: 1.2;
  
  text-align: center;
}

.info {
  color: #1a1a1d;
  font-size: 14px;
  line-height: 1.6;
  text-align: center;
}

</style>